.inputal{
    width: 600px;
    padding: 20px; 
    margin: 10px;
}

.form-container{
    display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.buttonal{
    background-color: navy;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    padding: 30px 40px 30px 40px;
    border-radius: 10px;
    border: none;
    margin-left: 40%;
}

.buttonal:hover{
    background-color: blue;
}


@media screen and (max-width: 480px) {
    .inputal{
        width: 100%;
        margin-left: 2%;
    }

    .buttonal{
        margin: 0px auto;
    }
}

@media (max-width: 575.98px) and (min-width: 480px) {
    .inputal{
        width: 100%;
        margin-left: 2%;
    }

    .buttonal{
        margin: 0px auto;
    }
}

@media (min-width: 576px) and (max-width: 768px) {
    .inputal{
        width: 100%;
        margin-left: 2%;
    }

    .buttonal{
        margin: 0px auto;
    }
}