 .businessunitsparentdiv{
    margin-top: 2%;
    height: auto;
    width: 100%;
 }

 .br{
    width: 100%;
    height: auto;
    display: flex;
    margin-top: 1%;
 }

 .br2{
    width: 100%;
    height: auto;
    display: flex;
    margin-top: 5%; 
 }

 .bx{
    border: 2px solid navy;
    width: 100%;
    margin-left: 2%;
    margin-right: 2%;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 2%;
    border-radius: 2%;
 }

@media only screen and (max-width: 414px){
    .br{
        display: flex;
        flex-direction: column;
    }
    .br2{
        display: flex;
        flex-direction: column;
    }
    .bx{
        margin-top: 5%;
    }
 }
@media screen and (max-width: 480px){
    .br{
        display: flex;
        flex-direction: column;
    }
    .br2{
        display: flex;
        flex-direction: column;
    }
    .bx{
        margin-top: 5%;
    }
}
@media (max-width: 575.98px) and (min-width: 414px){
    .br{
        display: flex;
        flex-direction: column;
    }
    .br2{
        display: flex;
        flex-direction: column;
    }
    .bx{
        margin-top: 5%;
    }
}
@media (min-width: 576px) and (max-width: 768px){
    .br{
        display: flex;
        flex-direction: column;
    }
    .br2{
        display: flex;
        flex-direction: column;
    }
    .bx{
        margin-top: 5%;
    }
}
