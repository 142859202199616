.mainimagediv{
    width: 100%;
}

.mainimage{
    width: 100%;
}

.partnerinbusinessgrowthdiv{
    width: 100%;
    margin-top: 5%;
    height: auto;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    padding-left: 5%;
    padding-right: 5%;
}

.tssadiv{
    margin-top: 5%;
    height: auto;
    width: 100%;
    display: flex;
}

.technologydiv{
    
    margin-top: 2%;
    width: 100%;
    margin-left: 1%;
    border-radius: 3%;
    background-color: navy;
    padding-left: 1%;
}

.ssmdiv{
    margin-top: 2%;
    width: 100%;
    margin-left: 1%;
    border-radius: 3%;
    background-color: darkred;
    padding-left: 1%;
}

.servicesanddeploymentdiv{
    margin-top: 2%;
    width: 100%;
    margin-left: 1%;
    border-radius: 3%;
    background-color: olive;
    padding-left: 1%;
}

.automationdiv{
    margin-top: 2%;
    width: 100%;
    margin-left: 1%;
    margin-right: 1%;
    border-radius: 3%;
    background-color: #fe9e2c;
    padding-left: 1%;
}

.technologypointsdiv{
    margin-top: 5%;
    height: auto;
    display: flex;
}

.technologypoints{
    margin-top: 2%;
    width: 100%;
    height: auto;
    background-color: navy;
    padding-left: 10%;
}

.technologyimage{
    margin-top: 2%;
    width: 30%;
}

.ti{
    width: 100%;
}

.ssmpointsdiv{
    margin-top: 5%;
    height: auto;
    display: flex;
}

.ssmpoints{
    margin-top: 2%;
    width: 100%;
    height: auto;
    background-color: darkred;
    padding-left: 10%;
}

.ssmimage{
    margin-top: 2%;
    width: 50%;
}

.ssmimg{
    width: 100%;
}

.servicespointsdiv{
    margin-top: 5%;
    height: auto;
    display: flex;
}

.servicespoints{
    margin-top: 2%;
    width: 100%;
    height: auto;
    background-color: olive;
    padding-left: 10%;
}

.servicesimage{
    margin-top: 2%;
    width: 50%;
}

.servicesimg{
    width: 100%;
}

.automationpointsdiv{
    margin-top: 5%;
    height: auto;
    display: flex;
}

.automationpoints{
    margin-top: 2%;
    width: 100%;
    height: auto;
    background-color: #fe9e2c;
    padding-left: 10%;
}

.automationimage{
    margin-top: 2%;
    width: 70%;
}

.automationimg{
    width: 100%;
}

.ourcustomersdiv{
    margin-top: 2%;
    width: 100%;
    height: auto; 
}

.row1{
    display: flex;
    width: 100%;
}

.row2,
.row3,
.row4,
.row5{
    display: flex;
    width: 100%;
    margin-top: 1%; 
}

.box1,
.box2,
.box3,
.box4,
.box5,
.box6,
.box7{
    width: 10%;
    margin-left: 3%;
}

.ahfimg,
.aiouimg,
.anpimg,
.biimg,
.buimg,
.cottonwebimg,
.imgcimg,
.jrimg,
.kayseriaimg,
.kiuimg,
.lciimg,
.leisureclubimg,
.lumsimg,
.mgimg,
.morinagaimg,
.ngcimg,
.ntdcimg,
.nuimg,
.pescoimg,
.pmdimg,
.rrimg,
.sefamimg,
.shamaimg,
.ssimg,
.stimg,
.texaslogoimg,
.texpakimg,
.uopimg,
.wapdaimg,
.willshireimg{
    width: 100%;
}

.locationdiv{
    width: 100%;
    margin-top: 2%;
}

.locationimg{
    width: 100%;
}

.ourprojectsdiv{
    margin-top: 2%;
    width: 100%;
    height: auto;
}

.pr1{
    width: 100%;
    margin-top: 2%;
    display: flex;
}

.prb1{
    width: 100%;
    margin-left: 2%;
    margin-right: 2%;
}

.aepl,
.aioup,
.bup,
.cwp,
.cwlp,
.dellemcp,
.grandstreamp,
.h3cp,
.lcip,
.lumsp,
.ntdcp,
.nap,
.nustp,
.pescop,
.riphah,
.pmdp,
.ssp,
.sap,
.sefamp,
.sophosp{
    width: 100%;
}

@media only screen and (max-width: 414px){
    .tssadiv{
        display: flex;
        flex-direction: column;
    }

    .technologypointsdiv{
        display: flex;
        flex-direction: column;
    }

    .technologyimage{
        width: 100%;
    }

    .ssmpointsdiv{
        display: flex;
        flex-direction: column;
    }

    .ssmimage{
        width: 100%;
    }

    .servicespointsdiv{
        display: flex;
        flex-direction: column;
    }

    .automationpointsdiv{
        display: flex;
        flex-direction: column;
    }

    .automationpoints{
        padding-left: 5%;
    }

    .technologypoints{
        padding-left: 5%;
    }

    .ssmpoints{
        padding-left: 5%;
    }

    .servicespoints{
        padding-left: 5%;
    }

    .automationimage{
        width: 100%;
    }
}

@media screen and (max-width: 480px){
    .tssadiv{
        display: flex;
        flex-direction: column;
    }

    .technologypointsdiv{
        display: flex;
        flex-direction: column;
    }

    .technologyimage{
        width: 100%;
    }

    .ssmpointsdiv{
        display: flex;
        flex-direction: column;
    }

    .ssmimage{
        width: 100%;
    }

    .servicespointsdiv{
        display: flex;
        flex-direction: column;
    }

    .servicesimage{
        width: 100%;
    }

    .automationpointsdiv{
        display: flex;
        flex-direction: column;
    }

    .automationpoints{
        padding-left: 5%;
    }

    .technologypoints{
        padding-left: 5%;
    }

    .ssmpoints{
        padding-left: 5%;
    }

    .servicespoints{
        padding-left: 5%;
    }

    .automationimage{
        width: 100%;
    }
}

@media (max-width: 575.98px) and (min-width: 414px){
    .tssadiv{
        display: flex;
        flex-direction: column;
    }

    .technologypointsdiv{
        display: flex;
        flex-direction: column;
    }

    .technologyimage{
        width: 100%;
    }

    .ssmpointsdiv{
        display: flex;
        flex-direction: column;
    }

    .ssmimage{
        width: 100%;
    }

    .servicespointsdiv{
        display: flex;
        flex-direction: column;
    }

    .servicesimage{
        width: 100%;
    }

    .automationpointsdiv{
        display: flex;
        flex-direction: column;
    }

    .automationpoints{
        padding-left: 5%;
    }

    .technologypoints{
        padding-left: 5%;
    }

    .ssmpoints{
        padding-left: 5%;
    }

    .servicespoints{
        padding-left: 5%;
    }

    .automationimage{
        width: 100%;
    }
}
@media (min-width: 576px) and (max-width: 768px){
    .tssadiv{
        display: flex;
        flex-direction: column;
    }

    .technologypointsdiv{
        display: flex;
        flex-direction: column;
    }

    .technologyimage{
        width: 100%;
    }

    .ssmpointsdiv{
        display: flex;
        flex-direction: column;
    }

    .ssmimage{
        width: 100%;
    }

    .servicespointsdiv{
        display: flex;
        flex-direction: column;
    }

    .servicesimage{
        width: 100%;
    }

    .automationpointsdiv{
        display: flex;
        flex-direction: column;
    }

    .automationpoints{
        padding-left: 5%;
    }

    .technologypoints{
        padding-left: 5%;
    }

    .ssmpoints{
        padding-left: 5%;
    }

    .servicespoints{
        padding-left: 5%;
    }

    .automationimage{
        width: 100%;
    }
}