/* Navbar.css */

/* Navbar styling */
.navbar {
  padding: 0.5rem 1rem;
}

.navbar-nav {
  margin-left: auto;
}

.nav-item {
  margin-left: 1rem;
}

.nav-link {
  color: #000; /* Default link color */
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #007bff; /* Blue color on hover */
}

.nav-link.active {
  color: #007bff; /* Blue color for active link */
}

/* Social Media Icon Colors */
.facebook {
  color: #1877f2; /* Facebook color */
}

.twitter {
  color: black; /* Twitter color */
}

.instagram {
  color: #c13584; /* Instagram color */
}

.youtube {
  color: #ff0000; /* YouTube color */
}

.logo {
  max-width: 150px; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
}
