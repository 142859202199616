/* Custom CSS for Footer */

/* Footer background color and text */
footer {
  background-color: navy;
  color: white;
}

/* Logo styling */
footer img {
  width: 150px; /* Adjust the size of the logo */
  margin-bottom: 10px;
}

/* List links style */
footer ul li {
  margin-bottom: 10px;
}

footer ul li a {
  color: white;
  font-size: 16px; /* Adjust font size */
  transition: color 0.3s;
}

footer ul li a:hover {
  color: lightgray; /* Change color on hover */
  text-decoration: none; /* Remove underline on hover */
}

/* Social icons hover effect */
footer .fa-facebook:hover,
footer .fa-twitter:hover,
footer .fa-youtube:hover,
footer .fa-linkedin:hover,
footer .fa-instagram:hover {
  color: lightgray;
}

/* Adjust margin for social icons */
footer .fa-facebook,
footer .fa-twitter,
footer .fa-youtube,
footer .fa-linkedin,
footer .fa-instagram {
  margin-right: 10px;
  transition: color 0.3s;
}

/* Address Section */
footer p {
  font-size: 14px;
  line-height: 24px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  footer img {
    width: 120px; /* Adjust the size of the logo for small screens */
  }

  footer p {
    font-size: 12px;
    line-height: 20px;
  }

  footer ul li a {
    font-size: 14px;
  }
}
