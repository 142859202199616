.splash-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    transition: opacity 1s ease-in-out;
}

.splash-screen img {
    width: 50%; /* Logo size */
}

.splash-screen h1 {
    font-size: 24px;
    margin-top: 20px;
    color: blue; /* Text color */
    font-family: Arial, Helvetica, sans-serif;
}

.slide-out {
    transform: translateX(-100%); /* Slide the splash screen to the left */
  }

@media only screen and (max-width: 414px){
    .splash-screen h1{
        text-align: center;
    }
}