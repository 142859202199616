.missionstatementdiv{
    width: 100%;
    height: auto;
    display: flex;
}

.missionstatement{
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 8%;
}

.missionimage{
    width: 100%;
}

.missionimg{
    width: 100%;
}

.visionstatementdiv{
    width: 100%;
    height: auto;
    display: flex;
    margin-top: 5%;
}

.visionstatement{
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 5%;
}

.visionimage{
    width: 100%;
}

.visionimg{
    width: 100%;
}

.yearonyeargrowthdiv{
    width: 100%;
    height: auto;
    margin-top: 5%;
    display: flex;
}

.yearonyeargrowthimage{
    width: 30%;
}

.yearonyeargrowth{
    width: 100%;
    background-color: navy;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 10%;
}

.yearimg{
    width: 100%;
}

.groupofcomapniesdiv{
    width: 100%;
    height: auto;
    margin-top: 5%;
    display: flex;
}

.groupofcompanies{
    width: 100%;
    background-color: navy;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 14%;
}

.groupofcompaniesimage{
    width: 30%;
}

.gocimg{
    width: 100%;
}

.noticaleprojectsdiv{
    width: 100%;
    height: auto;
    margin-top: 5%;
    display: flex;
}

.noticaleprojects{
    width: 100%;
    background-color: navy;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 2%;
}

.noticaleprojectsimage{
    width: 30%;
}

.noticaleimg{
    width: 100%;
}

@media only screen and (max-width: 414px){
    .missionstatementdiv{
        display: flex;
        flex-direction: column;
    }
    .missionimage{
        width: 100%;
    }
    .visionstatementdiv{
        display: flex;
        flex-direction: column;
    }
    .visionimage{
        width: 100%;
    }
    .yearonyeargrowthdiv{
        display: flex;
        flex-direction: column;
    }
    .yearonyeargrowthimage{
        width: 100%;
    }
    .groupofcomapniesdiv{
        display: flex;
        flex-direction: column;
    }
    .groupofcompaniesimage{
        width: 100%;
    }
    .noticaleprojectsdiv{
        display: flex;
        flex-direction: column;
    }
    .noticaleprojectsimage{
        width: 100%;
    }
}
@media screen and (max-width: 480px){
    .missionstatementdiv{
        display: flex;
        flex-direction: column;
    }
    .missionimage{
        width: 100%;
    }
    .visionstatementdiv{
        display: flex;
        flex-direction: column;
    }
    .visionimage{
        width: 100%;
    }
    .yearonyeargrowthdiv{
        display: flex;
        flex-direction: column;
    }
    .yearonyeargrowthimage{
        width: 100%;
    }
    .groupofcomapniesdiv{
        display: flex;
        flex-direction: column;
    }
    .groupofcompaniesimage{
        width: 100%;
    }
    .noticaleprojectsdiv{
        display: flex;
        flex-direction: column;
    }
    .noticaleprojectsimage{
        width: 100%;
    }
}
@media (max-width: 575.98px) and (min-width: 414px){
    .missionstatementdiv{
        display: flex;
        flex-direction: column;
    }
    .missionimage{
        width: 100%;
    }
    .visionstatementdiv{
        display: flex;
        flex-direction: column;
    }
    .visionimage{
        width: 100%;
    }
    .yearonyeargrowthdiv{
        display: flex;
        flex-direction: column;
    }
    .yearonyeargrowthimage{
        width: 100%;
    }
    .groupofcomapniesdiv{
        display: flex;
        flex-direction: column;
    }
    .groupofcompaniesimage{
        width: 100%;
    }
    .noticaleprojectsdiv{
        display: flex;
        flex-direction: column;
    }
    .noticaleprojectsimage{
        width: 100%;
    }
}
@media (min-width: 576px) and (max-width: 768px){
    .missionstatementdiv{
        display: flex;
        flex-direction: column;
    }
    .missionimage{
        width: 100%;
    }
    .visionstatementdiv{
        display: flex;
        flex-direction: column;
    }
    .visionimage{
        width: 100%;
    }
    .yearonyeargrowthdiv{
        display: flex;
        flex-direction: column;
    }
    .yearonyeargrowthimage{
        width: 100%;
    }
    .groupofcomapniesdiv{
        display: flex;
        flex-direction: column;
    }
    .groupofcompaniesimage{
        width: 100%;
    }
    .noticaleprojectsdiv{
        display: flex;
        flex-direction: column;
    }
    .noticaleprojectsimage{
        width: 100%;
    }
}