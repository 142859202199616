.ourpartnersdiv{
    width: 100%;
    height: auto;
}

.opr1{
    display: flex;
    width: 100%;
}

.opr2{
    display: flex;
    width: 100%;
    margin-top: 5%; 
}

.opb1{
    border-radius: 2%;
    border: 5px solid navy;
    width: 15%;
    margin-left: 4%;
}

.alhua,
.attom,
.awan,
.aws,
.brocade,
.citrix,
.cloud9,
.dellemc,
.dellt,
.digitus,
.fortinet,
.grandstream,
.h3c,
.hikvision,
.hp,
.hpe,
.huawei,
.ibm,
.iota,
.kaspersky,
.kemp,
.lenovo,
.microsoft,
.mm,
.molex,
.riverbed,
.ruckus,
.sangfor,
.sophos,
.trendnet,
.unv,
.vertiv,
.vmware,
.vivanco,
.vericom{
    width: 100%;
}

@media only screen and (max-width: 414px){
    .opr1,
    .opr2{
        display: flex;
        flex-direction: column;
    }
    .opb1{
        width: 100%;
        margin-top: 5%;
        margin-left: 0%;
    }
}
@media screen and (max-width: 480px){
    .opr1,
    .opr2{
        display: flex;
        flex-direction: column;
    }
    .opb1{
        width: 100%;
        margin-top: 5%;
        margin-left: 0%;
    }
}
@media (max-width: 575.98px) and (min-width: 414px){
    .opr1,
    .opr2{
        display: flex;
        flex-direction: column;
    }
    .opb1{
        width: 100%;
        margin-top: 5%;
        margin-left: 0%;
    }
}
@media (min-width: 576px) and (max-width: 768px){
    .opr1,
    .opr2{
        display: flex;
        flex-direction: column;
    }
    .opb1{
        width: 100%;
        margin-top: 5%;
        margin-left: 0%;
    }
}