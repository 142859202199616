.getintouchdiv{
    width: 100%;
    height: auto;
}

.getintouch{
    width: 100%;
    text-align: center;
    color: navy;
    font-family: Arial, Helvetica, sans-serif;
}

.cfacebook{
    font-size: x-large;
    color: #1877F2;
}

.ctwitter{
    font-size: x-large;
    margin-left: 2%;
    color: black;
}

.cyoutube{
    font-size: x-large;
    margin-left: 2%;
    color: #FF0000;
}

.clinkedin{
    font-size: x-large;
    margin-left: 2%;
    color: #0077B5;
}

.cinstagram{
    font-size: x-large;
    margin-left: 2%;
    color: #5B51D8;
}

.addressdiv{
    margin-top: 5%;
    text-align: center;
}

.falocation{
    font-size: xx-large;
    color: red;
}

.cediv{
    margin-top: 5%;
    display: flex;
    height: auto;
}

.cuo{
    border: 4px solid navy;
    width: 100%;
    text-align: center;
    border-radius: 1%;
    margin-left: 2%;
}

.eu{
    border: 4px solid navy;
    width: 100%;
    border-radius: 1%;
    text-align: center;
    margin-left: 2%;
    margin-right: 2%;
}

@media only screen and (max-width: 414px){
    .cediv{
        display: flex;
        flex-direction: column;
    }
    .cuo{
        margin-left: 0%;
    }
    .eu{
        margin-left: 0%;
        margin-top: 5%;
    }
}
@media screen and (max-width: 480px){
    .cediv{
        display: flex;
        flex-direction: column;
    }
    .cuo{
        margin-left: 0%;
    }
    .eu{
        margin-left: 0%;
        margin-top: 5%;
    }
}
@media (max-width: 575.98px) and (min-width: 414px){
    .cediv{
        display: flex;
        flex-direction: column;
    }
    .cuo{
        margin-left: 0%;
    }
    .eu{
        margin-left: 0%;
        margin-top: 5%;
    }
}
@media (min-width: 576px) and (max-width: 768px){
    .cediv{
        display: flex;
        flex-direction: column;
    }
    .cuo{
        margin-left: 0%;
    }
    .eu{
        margin-left: 0%;
        margin-top: 5%;
    }
}