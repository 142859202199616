.acparent{
    display: flex;
}

.acimg{
    width: 100%;
    height: auto;
}

.acimg1{
    width: 100%;
    height: 100%;
}
.acimg2{
    width: 100%;
}


.basic{
    display: flex; 
}
.basic10{
    width: 100%;
}
.basic10text{
    border: 2px solid transparent;
    width: 100%;
}
.basich5{
    line-height: 200%;
    margin-top: 30%;
}
.basicimg{
    width: 100%;
}


.range{
    display: flex;
}
.rangep{
    width: 100%;
}
.rangetext{
    width: 100%;
}
.range1p{
    width: 100%;
    height: 100%;
}
.range2p{
    width: 100%;
    height: 100%;
}


.specs{
    display: flex;
}
.specsp{
    width: 100%;
}
.specstext{
    width: 100%;
}
.specsimg{
    width: 100%;
}


.cabletrays{
    display: flex;
}
.cabletraysp{
    width: 100%;
}
.cabletraystext{
    width: 100%;
}
.ctimg{
    width: 100%;
}


.batterycabinet{
    display: flex;
}
.batterycabinettext{
    width: 100%;
}
.batterycabinetp{
    width: 100%;
}
.bcimg{
    width: 100%;
}

@media only screen and (max-width: 414px){
    .basic{
        display: flex;
        flex-direction: column;
    }
    .basich5{
        margin-top: 0%;
    }

    .range{
        display: flex;
        flex-direction: column;
    }
    .rangetext{
        margin-top: 2%;
    }

    .specs{
        display: flex;
        flex-direction: column;
    }

    .cabletrays{
        display: flex;
        flex-direction: column;
    }

    .batterycabinet{
        display: flex;
        flex-direction: column;
    }
}

@media screen and (max-width: 480px){
    .basic{
        display: flex;
        flex-direction: column;
    }
    .basich5{
        margin-top: 0%;
    }

    .range{
        display: flex;
        flex-direction: column;
    }
    .rangetext{
        margin-top: 2%;
    }

    .specs{
        display: flex;
        flex-direction: column;
    }

    .cabletrays{
        display: flex;
        flex-direction: column;
    }

    .batterycabinet{
        display: flex;
        flex-direction: column;
    }
}

@media (max-width: 575.98px) and (min-width: 414px){
    .basic{
        display: flex;
        flex-direction: column;
    }
    .basich5{
        margin-top: 0%;
    }

    .range{
        display: flex;
        flex-direction: column;
    }
    .rangetext{
        margin-top: 2%;
    }

    .specs{
        display: flex;
        flex-direction: column;
    }

    .cabletrays{
        display: flex;
        flex-direction: column;
    }

    .batterycabinet{
        display: flex;
        flex-direction: column;
    }
}

@media (min-width: 576px) and (max-width: 768px){
    .basic{
        display: flex;
        flex-direction: column;
    }
    .basich5{
        margin-top: 0%;
    }

    .range{
        display: flex;
        flex-direction: column;
    }
    .rangetext{
        margin-top: 2%;
    }

    .specs{
        display: flex;
        flex-direction: column;
    }

    .cabletrays{
        display: flex;
        flex-direction: column;
    }

    .batterycabinet{
        display: flex;
        flex-direction: column;
    }  
}